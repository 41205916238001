/* cSpell:disable */
/* spell-checker: disable */
enum PromoCodes {
  Apoquel = "AV2ZEXJKCC10",
  BravectoForCats = "0PFN922AK844",
  BravectoForDogs = "NKMBZ2VWKC1X",
  CapstarFleaTreatment = "BAW845GBAZ53",
  Clomicalm = "61KSAFRT9FK9",
  Credelio1Month = "H2TCEHBE9P7F",
  Credelio3Months = "3JJGKEYKN4KD",
  Credelio6Months = "43HCJ853T56B",
  CredelioCAT3Months = "PTHXVH9HASZ2",
  CredelioCAT6Months = "98KYS26QXZWZ",
  EicosaDermOmega3Liquid = "WJ7AHYQEQY3E",
  FecalFloatWithGiardiaELISAKit = "NHFPPHZTVYX0",
  FeliwayMultiCatStarterKit = "JK64AXY5DRDG",
  FeliwayOptimumDiffuserRefill = "T68D0G9JQ6TG",
  FeliwayTravelSpray = "7EQXEFYVF1Z8",
  FleaAndTick = "R3N2JWEDH0K0",
  Galliprant = "A4T3HYJX1PJ7",
  Incurin = "N5R0HA1JJXF5",
  MovoflexSoftChews = "3S6D0NJFAK51",
  NexGard1Month = "HC8G2DPS6GTR",
  NexGard3Months = "E489Z5QZ1WZF",
  NexGard6Months = "AK0D151ST5TV",
  NutraMaxCosequinDS = "Q8H0J2JEWWTA",
  Purina = "NP7HK43ETRSE",
  ShelterDiscount = "XN71BXXPHZ05",
  RevolutionForDogs = "5VDMS1EJF24S",
  Vectra3D3Months = "DP42EKZ83HBG",
  SimplicefRx = "HQSCXQ2AVC8R",
  FeliwayClassicKit = "Z4KWK8V4Y05S",
  BravectoForDogs1MonthRx = "TS14Q5NK7PJD",
  CereniaRx = "NM77FBER51E4",
  SelaridForDogs6MonthsRx = "FVMF2CMREAK1",
  EntyceForDogsRx = "HDSXFPMKV1CY",
  SelaridForCats3MonthsRx = "9P14D7EPAV7Y",
  DouxoS3PyoMousse = "PYSSZ8WHS654",
  MiratazOintmentRx = "QV2XS0VGTBEP",
  ZymoxLeaveOnConditioner = "4ETWJA6YD26K",
  CheristinFleaSpotTreatmentForCats = "8VM5BQR62XHD",
  HydroxyzineRx = "HQ1GGHDB8BTM",
  Cetirizine = "V6FPZFFS5QY7",
  VetriLysinePlusBiteSizedCatChews = "R6BFNNB4JYNE",
  DouxoS3CalmMousse = "RZ2EEH6G11KZ",
  SelaridForDogs3MonthsRx = "FT5GCMTH5WRY",
  DouxoS3CalmShampoo = "83WZW6WD2KXE",
  HeartgardPlus6ChewsRx = "DJV996J3E36B",
  DiaGelDiarrheaControlGelForDogs = "HGWQWEG3Q0YJ",
  TrizULTRAKetoFlush = "RN3DDAGH35SM",
  InterceptorPlus6ChewsRx = "V38VTXRN1QVG",
  DiaGelDiarrheaControlGelForCats = "Z3XS9Y0A9V33",
  DouxoS3PYOMousse = "28ZBP55RCBVD",
  SelaridForCats1MonthRx = "DEFF38RJ5YY6",
  SentinelForDogs6MonthsRx = "7AHKSBJBPRFT",
  CredelioCAT1MonthRx = "SA83JMAG463X",
  VetriScienceVetriLysinePlusBiteSizedCatChews = "Q3SHPM0DCTT8",
  CarprofenRx = "7M0AK5A9NQAZ",
  MaxiGuardOralCleansingWipes = "BAPW8712SN2M",
  DouxoS3PyoShampoo = "D1HY922KH22A",
  OnsiorForCatsRx = "ZYQ9J0JJA9DK",
  CefpodoximeRx = "DXQH1EHDFT7D",
  CapstarFleaOralTreatmentForDogs = "M79SXH6MCFS1",
  SelaridForDogs1MonthRx = "3BM2ZJ0PQ9ZB",
  FluoxetineRx = "MC5NSV51E01V",
  MiconaHexTrizShampooForDogsAndCats = "YAEKSDZ6HP5G",
  TrazodoneRx = "9YKFKER7D5HD",
  DouxoS3CareShampoo = "T4502RA5EN7H",
  BanophenAllergyTablet = "Q6K2WPJS0R3S",
  SurolanOticSuspensionRx = "HBE9KWG3F35H",
  DouxoS3PYOShampoo = "NMWW0KZFAEM2",
  VetericynEyeWashForPets = "EQJ2168ZX632",
  ZymoxOticPetEarTreatmentWithHydrocortisone = "JDNAC1VVP18G",
  KetoHexShampoo = "EZNSV3Q3SDF5",
  DouxoS3PYOWipes = "PQJ8ET98SE92",
  EpiOticAdvancedEarCleanser = "P3BM9YSNJ8ZS",
  ClavamoxChewableTabletRx = "AESNZNKNHNGX",
  DouxoS3PYOPads = "5TTBZTK2E0MT",
  VetericynHotSpotSpray = "1SS74S82736R",
  OmeprazoleRx = "VG9670J8WAS5",
  FamciclovirRx = "19D52K2C6T6E",
  TemarilPRx = "4TN8R70NQ336",
  CETEnzymaticDogAndCatPoultryFlavorToothpaste = "53JWA8AW29R8",
  MometamaxOticSuspensionRx = "Q99RRJY29KW0",
  EnteDermRx = "HW5XJQ24ABMY",
  KetoconazoleRx = "Q1GBGDRHYHVV",
  ClavamoxSuspensionRx = "ZTVW8G7N0R5P",
  Ancestry = "ANCESTRY10",
  FirstOrder30Off = "auto30",
  BulkUp = "BULKUP",
  Upgrade34Off = "UPGRADE34OFF",
  Upgrade40Off = "UPGRADE40OFF",
  Upgrade57Off = "UPGRADE57OFF",
  Ancestry45DollarsOffAnnual = "HFXA4FB7Q15S",
  CheckoutOffer = "STY0BZ2JN91D",
  Cheers10 = "CHEERS10",
  Cheers20 = "CHEERS20",
  Cheers30 = "CHEERS30",
  Cheers40 = "CHEERS40",
  RevolutionPlusForCats6Mo = "NATZXHFF66ZA",
  RevolutionPlusForCats3Mo = "DANZ8D196SZ0",
  RimadylRx = "79NMBRN4HX4T",
  ThunderEaseCalming = "EXZ679A8VHY3",
  ThunderEaseCalmingDiffuserKit = "EGM7W60Q3RGX",
  ThunderEaseCalmingDiffuserRefill = "180XGC17M7X0",
  VetriScienceComposurePro = "1HPMQHVYGJK6",
  SimparicaTrio6Months = "XRXV8QY4M604",
  ThunderEaseCallingCollarForDogs = "EXZ679A8VHY3",
  MometamaxOticSolution = "Q99RRJY29KW0",
  DouxoS3Pyo = "PYSSZ8WHS654",
  DouxoS3PyoWipes = "PQJ8ET98SE92",
  DouxoS3PyoPads = "5TTBZTK2E0MT",
  FleaTick20 = "FLEATICK20",
}

export { PromoCodes };
